<template>
  <b-sidebar
    :visible="isActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Добавить поле
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <div class="p-2">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>
        <b-row>
          <!-- Form -->
          <b-col
            cols="12"
          >
            <b-form-group
              label="Название *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"
                placeholder="Введите название"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Ключ *"
              label-for="key"
              description="Уникальный ключ сущности в рамках сайта. Допустимы прописные латинские буквы, цифры, дефис и нижнее подчеркивание"
            >
              <b-form-input
                id="key"
                v-model.trim="item.key"
                v-mask="{ regex: '^[a-z0-9][a-zA-Z0-9\\-_]*[a-zA-Z0-9]$', placeholder: '' }"
                placeholder="Введите ключ"
                :state="get(validation, 'key[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'key[0]')"
                class="text-danger"
              >
                {{ get(validation, 'key[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Описание"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model.trim="item.description"
                placeholder="Введите описание"
                :state="get(validation, 'description[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'description[0]')"
                class="text-danger"
              >
                {{ get(validation, 'description[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Тип"
              label-for="type"
            >
              <v-select
                id="type"
                v-model.trim="item.type"
                placeholder="Выберите тип"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="fieldTypes"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
                label="name"
                :class="{
                  'is-invalid': !!get(validation, 'type[0]')
                }"
              />
              <small
                v-if="!!get(validation, 'type[0]')"
                class="text-danger"
              >
                {{ get(validation, 'type[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Сортировка"
              label-for="sort"
              description="Чем больше число тем ниже"
            >
              <b-form-input
                id="sort"
                v-model.trim.number="item.sort_order"
                placeholder="Сортировка"
                :state="get(validation, 'sort_order[0]') ? false : null"
                type="number"
              />
              <small
                v-if="!!get(validation, 'sort_order[0]')"
                class="text-danger"
              >
                {{ get(validation, 'sort_order[0]') }}
              </small>
            </b-form-group>
          </b-col>

        </b-row>
        <!-- Form Actions -->
        <div class="d-flex mt-1">
          <b-button
            variant="primary"
            class="mr-2"
            @click="createItem"
          >
            Добавить
          </b-button>
          <b-button
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Отмена
          </b-button>
        </div>
      </div>
    </template>

  </b-sidebar>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BFormTextarea, BRow, BSidebar, BFormCheckbox, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { get } from 'lodash'
import { getCurrentInstance } from 'vue'
import useCrudCreate from '@/composables/useCrudCreate'
import { createEntityField } from '@/services/main-api/websites/externals/entity-fields'
import fieldTypes from '../composables/fieldTypes'

export default {
  name: 'AddFieldSidebar',
  components: {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BSidebar,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    vSelect,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    entityId: {
      type: [Number, String],
      default: null,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy

    const createEntity = async () => {
      await createEntityField({
        entity_id: props.entityId,
        name: instance.item.name,
        key: instance.item.key,
        description: instance.item.description,
        sort_order: instance.item.sort_order,
        type: instance.item.type,
      })
    }

    const {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      createItem,
    } = useCrudCreate({
      item: {
        id: null,
        entity_id: null,
        name: '',
        key: '',
        description: '',
        sort_order: 0,
        type: fieldTypes[0].value,
      },
      validation: {
        rules: {
          name: 'required',
          key: 'required',
        },
        customErrorMessages: {
          'required.name': 'Укажите название',
          'required.key': 'Укажите ключ',
        },
      },
      createEntity,
      getTextAfterCreation: () => 'Поле было создано.',
      runAfterCreation: () => {
        instance.$emit('completed')
        instance.$emit('update:is-active', false)
      },
    })

    return {
      fieldTypes,
      item,
      loading,
      validation,
      error_message,
      clearForm,
      createItem,
      get,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
