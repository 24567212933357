<template>
  <b-sidebar
    :visible="isActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Изменить поле
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <div class="p-2">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>
        <b-row>
          <!-- Form -->
          <b-col
            cols="12"
          >
            <b-form-group
              label="Название *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"
                placeholder="Введите название"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Ключ *"
              label-for="key"
              description="Уникальный ключ сущности в рамках сайта. Допустимы прописные латинские буквы, цифры, дефис и нижнее подчеркивание"
            >
              <b-form-input
                id="key"
                :value="item.key"
                placeholder="Введите ключ"
                :state="get(validation, 'key[0]') ? false : null"
                readonly
              />
              <small
                v-if="!!get(validation, 'key[0]')"
                class="text-danger"
              >
                {{ get(validation, 'key[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Описание"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model.trim="item.description"
                placeholder="Введите описание"
                :state="get(validation, 'description[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'description[0]')"
                class="text-danger"
              >
                {{ get(validation, 'description[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Тип"
              label-for="type"
            >
              <b-form-input
                id="key"
                :value="get(fieldTypes.find((type) => type.value === item.type), 'name')"
                placeholder="Выберите тип"
                :state="get(validation, 'type[0]') ? false : null"
                readonly
              />
              <small
                v-if="!!get(validation, 'type[0]')"
                class="text-danger"
              >
                {{ get(validation, 'type[0]') }}
              </small>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <b-form-group
              label="Сортировка"
              label-for="sort"
              description="Чем больше число тем ниже"
            >
              <b-form-input
                id="sort"
                v-model.trim.number="item.sort_order"
                placeholder="Сортировка"
                :state="get(validation, 'sort_order[0]') ? false : null"
                type="number"
              />
              <small
                v-if="!!get(validation, 'sort_order[0]')"
                class="text-danger"
              >
                {{ get(validation, 'sort_order[0]') }}
              </small>
            </b-form-group>
          </b-col>

        </b-row>
        <!-- Form Actions -->
        <div class="d-flex mt-1">
          <b-button
            variant="primary"
            class="mr-2"
            @click="updateItem"
          >
            Сохранить
          </b-button>
          <b-button
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Отмена
          </b-button>
        </div>
      </div>
    </template>

  </b-sidebar>
</template>

<script>
import {
  BButton, BCol, BFormGroup, BFormInput, BFormTextarea, BRow, BSidebar, BFormCheckbox, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { get } from 'lodash'
import { getCurrentInstance, watch } from 'vue'
import useCrudEdit from '@/composables/useCrudEdit'
import { updateEntityField } from '@/services/main-api/websites/externals/entity-fields'
import fieldTypes from '../composables/fieldTypes'

export default {
  name: 'EditFieldSidebar',
  components: {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BSidebar,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    vSelect,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    field: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy

    const updateEntity = async () => {
      await updateEntityField({
        id: instance.item.id,
        name: instance.item.name,
        description: instance.item.description,
        sort_order: instance.item.sort_order,
      })
    }

    const {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,
    } = useCrudEdit({
      item: {
        id: null,
        entity_id: null,
        name: '',
        key: '',
        description: '',
        sort_order: 0,
        type: fieldTypes[0].value,
      },
      validation: {
        rules: {
          name: 'required',
        },
        customErrorMessages: {
          'required.name': 'Укажите название',
        },
      },
      updateEntity,
      getTextAfterUpdate: () => 'Поле было изменено.',
      runAfterUpdate: () => {
        instance.$emit('completed')
        instance.$emit('update:is-active', false)
      },
    })

    watch(() => props.field, field => {
      if (field) {
        instance.item.id = field.id
        instance.item.entity_id = field.entity_id
        instance.item.name = field.name
        instance.item.key = field.key
        instance.item.description = field.description
        instance.item.sort_order = field.sort_order
        instance.item.type = field.type
      }
    })

    return {
      fieldTypes,
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,
      get,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
