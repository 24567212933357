<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных сущности
      </h4>
      <div class="alert-body">
        Не найдена сущность с этим идентификатором.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <app-breadcrumb
      page-title="Редактирование кастомной сущности"
      :breadcrumb="breadcrumb"
    />
    <b-card>
      <div class="">
        <b-row v-if="error_message">
          <b-col>
            <small
              class="text-danger d-block mb-1"
            >
              {{ error_message }}
            </small>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Название *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.trim="item.name"
                placeholder="Введите название"
                :state="get(validation, 'name[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'name[0]')"
                class="text-danger"
              >
                {{ get(validation, 'name[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Ключ *"
              label-for="key"
            >
              <b-form-input
                id="key"
                :value="item.key"
                placeholder="Введите ключ"
                readonly
                :state="get(validation, 'key[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'key[0]')"
                class="text-danger"
              >
                {{ get(validation, 'key[0]') }}
              </small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Описание"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model.trim="item.description"
                placeholder="Введите описание"
                :state="get(validation, 'description[0]') ? false : null"
              />
              <small
                v-if="!!get(validation, 'description[0]')"
                class="text-danger"
              >
                {{ get(validation, 'description[0]') }}
              </small>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updateItem"
            >
              Сохранить
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <model-fields
      :entity-id="id"
    />
  </b-overlay>
</template>

<script>
import {
  BAlert, BButton, BCard, BCol, BFormGroup, BFormInput, BLink, BOverlay, BRow, BTab, BTabs,
  BFormTextarea, BInputGroup, BCardHeader,
  BCardTitle, BTable, BFormCheckbox, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import get from 'lodash/get'
import {
  getCurrentInstance, ref, onMounted, computed,
} from 'vue'
import { updateEntity as updateEntityApi, getEntity as getEntityApi } from '@/services/main-api/websites/externals/entities'
import useCrudEdit from '@/composables/useCrudEdit'
import AppBreadcrumb from '@/layouts/components/AppBreadcrumb.vue'
import { getWebsite as getWebsiteApi } from '@/services/main-api/websites/externals/websites'
import ModelFields from '../components/ModelFields.vue'

export default {
  name: 'EditCustomModelView',
  components: {
    ModelFields,
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    vSelect,
    BTab,
    BTabs,
    BFormCheckbox,
    BFormTextarea,
    BInputGroup,
    BCardHeader,
    BCardTitle,
    BTable,
    BAvatar,
    AppBreadcrumb,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const getItemError = ref(false)

    // BEGIN Site
    const site = ref(null)

    const getSite = async () => {
      try {
        site.value = (await getWebsiteApi({
          id: instance.item.website_id,
        })).data.data
      } catch (e) {
        site.value = null
      }
    }
    // END Site

    const getItem = async () => {
      try {
        const res = await getEntityApi({ id: props.id })
        instance.item = res.data.data

        await getSite()
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    const updateEntity = async () => {
      await updateEntityApi({
        id: instance.item.id,
        name: instance.item.name,
        description: instance.item.description,
      })
    }

    // BEGIN breadcrumb
    const breadcrumb = computed(() => [
      {
        text: 'Сайты',
        to: {
          name: 'sites',
        },
      },
      {
        text: get(site.value, 'name', 'Сайт'),
        to: {
          name: 'sites.external.show',
          params: {
            id: get(site.value, 'id', null),
          },
        },
      },
      {
        text: 'Редактирование кастомной сущности',
        active: true,
      },
    ])
    // END breadcrumb

    const {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,
    } = useCrudEdit({
      item: {
        id: null,
        name: '',
        key: '',
        description: '',
      },
      validation: {
        rules: {
          name: 'required',
        },
        customErrorMessages: {
          'required.name': 'Укажите название',
        },
      },
      getItem,
      updateEntity,
      getTextAfterUpdate: () => 'Сущность была изменена.',
      runAfterUpdate: async () => {
        await instance.$router.go(-1)
      },
      getMoreData: async () => {
        // ...
      },
    })

    onMounted(() => {
      if (!instance.$store.getters['workingMode/mode']) {
        instance.$router.push({ name: 'dashboard' })
      }
    })

    return {
      item,
      loading,
      validation,
      error_message,
      clearForm,
      updateItem,

      breadcrumb,

      get,
      getItemError,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
