<template>
  <b-overlay
    :show="loading"
    spinner-variant="primary"
  >
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- left -->
            <h5>Поля</h5>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="isAddFieldSidebarOpen = true"
              >
                <span class="text-nowrap">Создать</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCompanyListTable"
        class="position-relative list-view-table"
        :items="items"
        responsive
        :fields="[
          { key: 'name', label: 'Название' },
          { key: 'key', label: 'Ключ' },
          { key: 'description', label: 'Описание' },
          { key: 'type', label: 'Тип' },
          { key: 'sort_order', label: 'Сортировка' },
          { key: 'actions', label: 'Действия' },
        ]"
        primary-key="key"
        :sort-by="'sort_order'"
        show-empty
        empty-text="Ничего не найдено!"
        :sort-desc="false"
      >
        <template #cell(type)="data">
          {{ get(fieldTypes.find((item) => item.value === data.item.type), 'name') }}
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <template>
              <b-dropdown-item
                @click="showEditItemSidebar(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Изменить</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteItem(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Удалить</span>
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
    <add-field-sidebar
      :is-active.sync="isAddFieldSidebarOpen"
      :entity-id="entityId"
      @completed="getData"
    />
    <edit-field-sidebar
      :is-active.sync="isEditFieldSidebarOpen"
      :field="editableItem"
      @completed="getData"
    />
  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BButton, BTable, BDropdown, BDropdownItem, BOverlay, BLink, BBadge, BAlert,
} from 'bootstrap-vue'
import { ref, onMounted, nextTick } from 'vue'
import get from 'lodash/get'
import useDeleteItem from '@/composables/useDeleieItem'
import { getEntityFields, deleteEntityField } from '@/services/main-api/websites/externals/entity-fields'
import AddFieldSidebar from '@/modules/sites-section/site-external-section/custom-models/components/AddlFieldSidebar.vue'
import EditFieldSidebar from '@/modules/sites-section/site-external-section/custom-models/components/EditFieldSidebar.vue'
import fieldTypes from '../composables/fieldTypes'

export default {
  name: 'ModelFields',
  components: {
    AddFieldSidebar,
    EditFieldSidebar,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BLink,
    BBadge,
    BAlert,
  },
  props: {
    entityId: {
      type: [Number, String],
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false)
    const items = ref([])

    const editableItem = ref(null)
    const isAddFieldSidebarOpen = ref(false)
    const isEditFieldSidebarOpen = ref(false)

    const showEditItemSidebar = async item => {
      editableItem.value = null
      await nextTick()
      editableItem.value = item
      isEditFieldSidebarOpen.value = true
    }

    const getItems = async () => {
      try {
        const res = await getEntityFields({
          entityID: props.entityId,
          orderBy: 'sort_order',
          sortedBy: 'asc',
          perPage: 1000,
        })

        items.value = res.data.data
      } catch (e) {
        items.value = []
      }
    }

    const getData = async () => {
      loading.value = true
      await getItems()
      loading.value = false
    }

    const { deleteItem } = useDeleteItem({
      deleteHandler: async item => deleteEntityField(item.id),
      getQuestionTextBeforeDeletion: item => `Поле <strong>${item.name}</strong> будет удалено`,
      getTextAfterDeletion: () => 'Поле было удалено.',
      runAfterDeletion: async () => {
        await getData()
      },
    })

    onMounted(() => {
      getData()
    })

    return {
      loading,
      items,
      fieldTypes,
      editableItem,

      deleteItem,
      showEditItemSidebar,
      getData,

      isAddFieldSidebarOpen,
      isEditFieldSidebarOpen,

      get,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
